@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: #e01a76;
}

a {
  color: #0366d6;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

body {
  margin: 0;
  font-family: "Roboto", Helvetica Neue, Helvetica, Arial;
  font-weight: 400;
  font-size: 0.97rem;
  background-color: #f7f7f7;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23008aff' fill-opacity='0.11' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

p {
  line-height: 1.25;
}
.lh-sm {
  line-height: 1.15;
}
.smile .fa-secondary {
  fill: #5a5a5a;
  opacity: 0.4;
}

.smile .fa-primary {
  fill: #5a5a5a;
  opacity: 1;
}

.title {
  font-size: 3.75em;
  line-height: 1.25;
  letter-spacing: -0.02em;
}

.popover {
  min-width: 12rem;
  max-width: 450px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 5px;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

.dropdown-menu {
  min-width: 15rem;
}

.font-style-1 {
  font-family: "Roboto", cursive;
}

h2 {
  font-size: 2.25rem;
}

#root {
  position: relative;
  min-height: 100vh;
}
#root::after {
  content: "";
  display: block;
  height: 2.5rem; /* Set same as footer's height */
}
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
}

strong {
  font-weight: 600;
}

.staffing-panel .shadow {
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.7) !important;
}

.card-hover {
  position: relative;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card-hover::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card-hover:hover {
  -webkit-transform: scale(1.03, 1.03);
  transform: scale(1.03, 1.03);
}

.card-hover:hover::after {
  opacity: 1;
}

.hover-and-scale {
  position: relative;
  display: inline-block;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hover-and-scale::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hover-and-scale:hover {
  -webkit-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.hover-and-scale:hover::after {
  opacity: 1;
}

a {
  cursor: pointer;
}

.scale-hover {
  position: relative;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.scale-hover::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.scale-hover:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}
.scale-hover:hover::after {
  opacity: 1;
}

.fader-enter {
  opacity: 0;
}
.fader-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.fader-exit {
  opacity: 1;
}
.fader-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.card-patient-record {
  min-height: 500px;
}
.nav-item-hover {
  position: relative;
  display: inline-block;
  border-radius: 5px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.nav-item-hover::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.nav-item-hover:hover {
  border: none;
  -webkit-transform: scale(1.25, 1.25);
  transform: scale(1.25, 1.25);
}

.nav-item-hover:hover::after {
  opacity: 1;
}
.card-menu {
  box-shadow: 0px 2px 3px rgb(0 0 0 / 40%);
}
.nav-menu a.dropdown-item.disabled {
  background: rgba(90, 90, 110, 0.2) !important;
  color: rgba(90, 90, 90, 1) !important;
  transition: all 0.75s ease;
}

.nav-menu a.dropdown-item.disabled:hover {
  background: rgba(227, 227, 227, 0.55) !important;
  color: rgba(200, 200, 200, 0.9) !important;
  border-left: solid 5px rgba(90, 90, 90, 0.55);
  cursor: not-allowed;
}

.nav-menu a.dropdown-item {
  background: rgba(30, 100, 150, 0.2) !important;
  color: rgba(30, 100, 150, 9) !important;
  transition: all 0.75s ease;
  font-weight: 500;
}

.nav-menu a.dropdown-item.active {
  background: rgba(235, 234, 208, 0.55) !important;
  border-left: solid 5px rgba(21, 131, 210, 0.65);
  color: rgba(35, 35, 55, 0.9) !important;
  transition: all 0.75s ease;
  font-weight: 500;
}
.nav-menu a.dropdown-item:active:hover {
  background: rgba(235, 234, 190, 0.75) !important;
  border-left: solid 9px rgba(18, 151, 246, 0.75);
  color: rgba(35, 35, 55, 0.9) !important;
  transition: all 0.75s ease;
  font-weight: 500;
}

.nav-menu a.dropdown-item:hover {
  background: rgba(227, 227, 227, 0.55) !important;
  border-left: solid 5px rgba(30, 100, 150, 0.55);
  color: rgba(35, 35, 55, 0.9) !important;
}

h4.text-primary {
  color: rgba(44, 171, 227, 0.9) !important;
}

.lh-lg {
  line-height: 1.5;
}

.text-gradient {
  text-shadow: 2px 2px rgba(60, 60, 100, 0.2);
}

.text-xs {
  font-size: 0.7rem;
}
.text-xxs {
  font-size: 0.6rem;
}

.text-sm {
  font-size: 0.82rem;
}

.text-md {
  font-size: 0.9rem;
}

.text-lg {
  font-size: 1rem;
}

.text-xl {
  font-size: 1.2rem;
}

.text-female {
  color: #fd79d0 !important;
}

.text-orange {
  color: #fd7e14 !important;
}

.t-light {
  font-family: "Roboto";
  font-weight: 100;
}

.t-medium {
  font-family: "Roboto";
  font-weight: 300;
}

.t-bold {
  font-family: "Roboto";
  font-weight: 600;
}

.t-black {
  font-family: "Roboto";
  font-weight: 900;
}

.badge-muted {
  background: #ddd !important;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.dropdown-menu {
  z-index: 9999;
}

.card.panel-data {
  min-height: 500px;
  display: block;
  border: solid 1px #ccc;
  border-radius: 5px;
}

.sk-circle {
  margin: 20px auto;
  width: 55px;
  height: 55px;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #690;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media (min-width: 512px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
}

@media (min-width: 1100px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 9em;
  height: 9em;
}

.loader {
  color: #555;
  margin: 60px auto;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(30, 30, 30, 0.2);
  border-right: 1.1em solid rgba(30, 30, 30, 0.2);
  border-bottom: 1.1em solid rgba(30, 30, 30, 0.2);
  border-left: 1.1em solid #ddd;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (min-width: 800px) {
  .modal-lg {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 850px;
  }
}

@media (min-width: 1098px) {
  .modal-lg {
    max-width: 950px;
  }
}

@media (min-width: 1200px) {
  .modal-lg {
    max-width: 1100px;
  }
}

@media (min-width: 1400px) {
  .modal-lg {
    max-width: 1300px;
  }
}

.smaller {
  font-size: 0.8rem;
}

.small.smaller {
  font-size: 0.7rem;
}

.abs {
  position: absolute;
}

.pos-l {
  left: 2px;
}

.pos-r {
  right: 2px;
}

.pos-l-snug {
  left: -1px;
}

.pos-r-snug {
  right: -1px;
}

.pos-b {
  bottom: 2px;
}

.pos-b-outer {
  bottom: -16px;
}

.pos-t-outer {
  top: -15px;
}

.pos-l-outer {
  left: -12px;
}

.pos-l-outer-half {
  left: -6px;
}

.pos-r-outer {
  right: -12px;
}

.pos-t {
  top: 2px;
}

.panel-wards .list-group-item {
  display: block;
  line-height: 1em;
  padding: 0.3em 0.5em 0.3em 0.2em;
  border: none;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

.badge.badge-pdd {
  line-height: 0.7em;
  padding: 2px 10px;
  border-radius: 12px 12px 0 0;
}

.badge.badge-pdd.bg-warning {
  background: #db701a;
}

.badge.badge-pdd.bg-danger {
  background: #db1a1a;
}

.badge.badge-pdd.bg-success {
  background: #5ab329;
}

.card.empty {
  background: #f0f0f0;
  opacity: 0.5;
}

.card.male {
  background: #ecf8ff;
  border-color: #cce2ef;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

.card.female {
  background: #ffeefa;
  border-color: #eec8e3;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

.female .badge {
  background: #ffeefa;
  border-color: #eec8e3 !important;
}

.male .badge {
  background: #ecf8ff;
  border-color: #cce2ef !important;
}

.panel-bed {
  line-height: 1em;
  position: relative;
  height: 70px;
  border-top-right-radius: 0;
}

.panel-bed .text-danger {
  color: #db1a1a;
  font-size: 1.05em;
}

.card,
.list-group,
.list-group-item {
  display: block;
}

.panel-bed .text-warning {
  color: #e99830 !important;
  font-size: 1.03em;
}

.panel-bed .text-success {
  color: #5ab329;
}

.lh-1 {
  font-size: 0.65em;
  line-height: 1;
}

.lh-xs-09 {
  line-height: 0.9;
}

.lh-xs-1 {
  line-height: 1;
}

.lh-xs-115 {
  line-height: 1.15;
}

.lh-xs-125 {
  line-height: 1.25;
}

.panel-bed.occupied {
  cursor: pointer;
}

.panel-bed .card-body {
  padding-top: 0.5em;
}

.badge.badge-pdd {
  line-height: 0.7em;
  padding: 2px 10px;
  border-radius: 10px 10px 0 0;
  border: solid 1px;
  border-bottom: 0px;
}

.badge.badge-pdd.bg-warning {
  background: #db701a;
}

.badge.badge-pdd.bg-danger {
  background: #db1a1a;
}

.badge.badge-pdd.bg-success {
  background: #5ab329;
}

.badge-tl {
  border-radius: 0 0 15px 0;
  margin-top: 0;
}

.response-table {
  border: solid 2px #4382d0;
  margin: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.response-table > tbody > tr > th,
.response-table > tbody > tr > td {
  border: solid 1px #bbb;
  padding: 2px 5px;
  font-size: 10pt;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.response-table > thead > tr > th {
  border: none;
  padding: 2px 5px;
  background: #4382d0;
  color: #fff;
  font-size: 11pt;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.badge.patient-identifier {
  display: inline-block;
  font-size: 0.9rem;
  padding:0.35rem 0.75rem;
  margin-right: .5rem;
  border: solid 1px #7fb7fc;
  background: #3474c2;
  color: #d5f3ff;
  box-shadow: 3px 3px #555;
  vertical-align:top;
  transition: all ease-in-out 0.3s;
}
.badge.patient-identifier:hover {
  cursor: pointer;
  border: solid 1px #9dd7fd !important;
  background: #406086 !important;
  color: #fffec3 !important;
  box-shadow: 3px 3px #494949 !important;
}
#patientBanner {
  font-family: 'Roboto';
  letter-spacing: 0.03rem;
  color: #fff !important;
}
#patientBanner .patient-name {
  font-size: 1.55rem;
  padding-bottom: 0.75rem;
  color: #fff;
}
#patientBanner .patient-gender {
  font-size: 1.15rem;
}
#patientBanner .patient-address {
  font-size: 0.75rem;
  color: #ddd;
}
.patient-banner-heading {
  font-size: 1.4rem;
}

@-webkit-keyframes heart-beat {
  15% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes heart-beat {
  15% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.fa-heartbeat-p60:hover i,
.fa-heartbeat-p60:hover svg {
  -webkit-animation: heart-beat 850ms infinite;
          animation: heart-beat 850ms infinite;
  stroke: #ec6d46;
}

.text-theme-1 {
  color: rgba(126, 50, 137, 1) !important;
}

.text-theme-2 {
  color: rgba(217, 27, 93, 1) !important;
}

.text-theme-3 {
  color: rgba(40, 171, 227, 1) !important;
}

.bg-theme-1 {
  background-color: rgba(126, 50, 137, 1) !important;
}

.bg-theme-2 {
  background-color: rgba(217, 27, 93, 1) !important;
}

.bg-theme-3 {
  background-color: rgba(40, 171, 227, 1) !important;
}

.bg-female {
  background-color: rgba(255, 90, 150, 0.25) !important;
}

.bg-male {
  background-color: rgba(90, 195, 255, 0.25) !important;
}

.filter {
  background-color: rgba(60, 60, 100, 0.9) !important;
}

.bg-female.bg-darker {
  background-color: rgba(255, 90, 150, 0.4) !important;
}

.bg-male.bg-darker {
  background-color: rgba(90, 195, 255, 0.4) !important;
}

.bg-female.bg-lighter {
  background-color: rgba(255, 90, 150, 0.1) !important;
}

.bg-male.bg-lighter {
  background-color: rgba(90, 195, 255, 0.1) !important;
}

.bg-contacts {
  background-color: rgba(60, 200, 30, 0.1) !important;
}

.bg-header {
  background-color: rgba(30, 160, 200, 0.1) !important;
}

.bg-table {
  background-color: rgba(46, 106, 167, 0.1) !important;
}

.bg-medication {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.bg-medicationList {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.bg-PRmedicationList {
  background-color: rgba(128, 128, 128, 0.2) !important;
}

.bg-PRmedicationNoTime {
  opacity: 0.25;
}

.bg-emptybed {
  opacity: 0.5;
}

.bg-PRmedicinedetails {
  background-color: rgba(0, 123, 255, 0.15) !important;
}

.bg-PRmedicinetimes {
  background-color: rgba(128, 128, 128, 0.15) !important;
}

.bg-problems {
  background-color: rgba(128, 128, 128, 0.12) !important;
}

.bg-allergies {
  background-color: rgba(128, 128, 128, 0.12) !important;
}

.bg-PFResults {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.bg-PFDeath {
  background-color: rgba(150, 0, 0, 0.15) !important;
}

.bg-appointments {
  background: #dbeaf4 !important;
}

.bg-admissions {
  background: #dbeaf4 !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-secondary:hover {
  color: #fff !important;
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}

.card.card-bed {
  height: 90px;
  transition: 0.5s;
}

.card.card-bed:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.card.card-bed.bg-female:hover {
  background-color: rgba(255, 90, 150, 0.3) !important;
}

.card.card-bed.bg-male:hover {
  background-color: rgba(90, 195, 255, 0.4) !important;
}

/* .patient-name {
  font-size: 1.65em;
  font-family: "Titillium Bold", Helvetica, Arial;
  color: #68cfff;
}

.patient-dob {
  font-family: "Titillium", Helvetica, Arial;
} */

.Lungs {
  --fa-primary-color: red;
  --fa-secondary-color: black;
}

.Smile {
  --fa-primary-color: black;
  --fa-secondary-color: yellow;
}

.NavBarBorderR {
  border-right: solid 1px rgba(0, 0, 0, 0.4);
}

.NavBarBorderL {
  border-left: solid 1px rgba(0, 0, 0, 0.4);
}
.panel > .list-group-appointments .list-group-item,
.panel > .panel-collapse > .list-group-appointments .list-group-item {
  border-width: 1px 0 1px 3px;
}

li.status-attended {
  border-left: solid 3px #44b464;
}

li.status-booked {
  border-left: solid 3px #4382d0;
}

li.status-dna {
  border-left: solid 3px #d04343;
}

li.status-cancelled {
  border-left: solid 3px #ba43d0;
}

.status-attended .badge-status {
  background: #44b464;
}

.status-booked .badge-status {
  background: #4382d0;
}

.status-dna .badge-status {
  background: #d04343;
}

.status-cancelled .badge-status {
  background: #ba43d0;
}

.card > .list-group-admissions .list-group-item,
.card > .panel-collapse > .list-group-admissions .list-group-item {
  border-width: 1px 0 1px 3px;
}

li.status-adm {
  border-left: solid 3px #44b464;
}

li.status-ref {
  border-left: solid 3px #90b444;
}

li.status-dis {
  border-left: solid 3px #4382d0;
}

li.status-pre,
li.status-sch {
  border-left: solid 3px #ba43d0;
}

.status-adm .badge-status {
  background: #44b464;
}

.status-ref .badge-status {
  background: #90b444;
}

.status-dis .badge-status {
  background: #4382d0;
}

.status-sch .badge-status,
.status-pre .badge-status {
  background: #ba43d0;
}

.nav-link.nav-link.active {
  background-color: #fff !important;
}

#patientNav .svg-inline--fa {
  vertical-align: middle !important;
}

.row-question {
  /*border-bottom: solid 1px #eee;*/
  font-size: 1.1em;
}

.question {
  color: #555;
  font-weight: 500;
  padding: 3px 8px;
  padding-left: 15px;
  vertical-align: top;
}

.question-response {
  font-weight: 400;
  padding: 3px 8px;
  word-wrap: break-word;
}

.question-response-full {
  color: #555;
  padding: 8px 12px;
}

.question-response-table {
  padding: 1px;
  clear: both;
}

.question-information {
  color: #4180cf;
  font-weight: 700;
  font-size: 0.95em;
}

.letter-h3 {
  font-family: "Roboto";
  margin-top: 0.9em;
  margin-bottom: 0.5em;
  font-size: 1.3em;
  color: #4382d0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.letter-h2 {
  margin-top: 1em;
  font-size: 1.9em;
  color: #777;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.letter-h4 {
  margin-top: 0.6em;
  font-size: 1.2em;
  color: #999;
}

.font-style-2 {
  font-family: "Teko", sans-serif;
}

.title2 {
  font-size: 3.75em;
  line-height: 1.25;
  letter-spacing: -0.02em;
}

.underline-on-hover:hover {
  text-decoration: underline !important;
}

.PopoverHeader {
  color: rgb(255, 220, 85) !important;
  background-color: rgba(20, 50, 115, 0.9) !important;
  font-weight: 600;
  line-height: 1.1;
  font-size: 1.2rem;
}

.PopoverBody {
  font-weight: 600;
  line-height: 1.1;
  font-size: 1.2rem;
  background-color: rgba(255, 220, 85, 0.5) !important;
  color: rgb(0, 60, 180) !important;
}

.hover-and-spin {
  border-radius: 50%;
  transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out, -webkit-transform 0.8s ease-in-out;
}

.hover-and-spin:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.pointer {
  cursor: pointer;
}

.text-lightgrey {
  color: rgba(0, 0, 0, 0.4) !important;
}
.hover-and-scale:hover {
  -webkit-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
  z-index: 9999;
}

.hover-and-scale:hover::after {
  opacity: 1;
}
.bg-appointments {
  background: #dbeaf4 !important;
}
.canvas {
  background-color: rgba(60, 60, 100, 0.9) !important;
  height: 100%;
  overflow-y: scroll;
  top: 66px !important;
}

.bg-info-light {
  background-color: rgba(0, 123, 255, 0.3);
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 30%;
  display: inline-block;
  -webkit-animation-name: move;
          animation-name: move;
  -webkit-animation-duration: 2500ms;
          animation-duration: 2500ms;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  offset-path: path("M0,50 C80,50 200,50 275,50");
}
.circle.circle-path-1 {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  offset-path: path("M0,50 C80,50 200,50 275,50");
  background-color: rgba(0, 123, 255, .9);
}
.circle.circle-path-2 {
  -webkit-animation-delay: 150ms;
          animation-delay: 150ms;
  offset-path: path("M0,50 C80,50 200,50 275,50");
  background-color: rgba(0, 123, 255, .7);
}
.circle.circle-path-3 {
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
  offset-path: path("M0,50 C80,50 220,50 275,50");
  background-color: rgba(0, 123, 255, .5);
}
.circle.circle-path-4 {
  -webkit-animation-delay: 350ms;
          animation-delay: 350ms;
  offset-path: path("M0,50 C80,50 200,50 275,50");
  background-color: rgba(0, 123, 255, .3);
}
.circle.circle-path-5 {
  -webkit-animation-delay: 450ms;
          animation-delay: 450ms;
  offset-path: path("M0,50 C80,50 220,50 275,50");
  background-color: rgba(0, 123, 255, .15);
}

@-webkit-keyframes move {
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes move {
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
}
.icon-hover:hover {
  box-shadow: 0.1 0.275rem 0.275rem rgba(0, 0, 0, 0.125) !important;
  border: solid 1px #1b6ec2 !important;
  background-color: #fce599 !important;
}
.section > .header > h2,
#contentPlaceholder_body_migdata h2,
.ihrTitlePaneTitle > span {
    font-family: Helvetica, Arial;
    font-size: 1.35em;
    color: #690;
    margin-top: .75em;
}

.section > .content h3,
 .title {
    font-family: Helvetica, Arial;
    font-size: 1.1em;
    font-weight: bold;
    color: #666;
    margin-top: .75em;
}

#contentPlaceholder_body_migdata table th:first-of-type,
#contentPlaceholder_body_migdata table td:first-of-type {
    width: 175px;
}

#contentPlaceholder_body_migdata .ihrTitlePaneTextNode {
    margin-top: 1em;
    display: block;
}

.nav-tabs > li > a {
    padding: 5px 8px;
    font-size: .85em;
}

.alternate td,
.even-row td {
    background-color: rgba(0,0,0,.1);
}

.tabs {
    display: none;
}

.message {
    margin-bottom: .75em;
}

.date,
.onsetdate {
    font-size: .85em;
    padding-right: 1.25em;
    color: #555;
}

.abnormal {
    width: 20px;
    font-weight: 900;
    color: #A90000;
}

.result {
    font-weight: 600;
}

.range {
    color: #666;
}

td.expandable-container {
    background-color: #eee;
    padding: 25px;
}

.expandable-container table {
    font-size: .85em;
}

    .expandable-container table td {
        border: solid 1px #ddd;
    }

.title {
    color: #666;
}

