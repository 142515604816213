.section > .header > h2,
#contentPlaceholder_body_migdata h2,
.ihrTitlePaneTitle > span {
    font-family: Helvetica, Arial;
    font-size: 1.35em;
    color: #690;
    margin-top: .75em;
}

.section > .content h3,
 .title {
    font-family: Helvetica, Arial;
    font-size: 1.1em;
    font-weight: bold;
    color: #666;
    margin-top: .75em;
}

#contentPlaceholder_body_migdata table th:first-of-type,
#contentPlaceholder_body_migdata table td:first-of-type {
    width: 175px;
}

#contentPlaceholder_body_migdata .ihrTitlePaneTextNode {
    margin-top: 1em;
    display: block;
}

.nav-tabs > li > a {
    padding: 5px 8px;
    font-size: .85em;
}

.alternate td,
.even-row td {
    background-color: rgba(0,0,0,.1);
}

.tabs {
    display: none;
}

.message {
    margin-bottom: .75em;
}

.date,
.onsetdate {
    font-size: .85em;
    padding-right: 1.25em;
    color: #555;
}

.abnormal {
    width: 20px;
    font-weight: 900;
    color: #A90000;
}

.result {
    font-weight: 600;
}

.range {
    color: #666;
}

td.expandable-container {
    background-color: #eee;
    padding: 25px;
}

.expandable-container table {
    font-size: .85em;
}

    .expandable-container table td {
        border: solid 1px #ddd;
    }

.title {
    color: #666;
}
